import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "modal fade show",
  id: "kt_modal_add_event",
  "aria-modal": "true",
  role: "dialog",
  ref: "newTargetModalRef"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "btn btn-icon btn-sm btn-active-icon-primary",
  id: "kt_modal_add_event_close",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_8 = { class: "fv-row mb-9 fv-plugins-icon-container" }
const _hoisted_9 = { class: "fv-row mb-9" }
const _hoisted_10 = { class: "fv-row mb-9" }
const _hoisted_11 = { class: "fv-row mb-9" }
const _hoisted_12 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_13 = { class: "row row-cols-lg-2 g-10" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "fv-row mb-9 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid" }
const _hoisted_16 = { class: "row row-cols-lg-2 g-10" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "fv-row mb-9 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid" }
const _hoisted_19 = { class: "modal-footer flex-center" }
const _hoisted_20 = ["data-kt-indicator"]
const _hoisted_21 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_22 = { class: "svg-icon svg-icon-3 ms-2 me-0" }
const _hoisted_23 = {
  key: 1,
  class: "indicator-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          class: "form fv-plugins-bootstrap5 fv-plugins-framework",
          id: "kt_modal_add_event_form",
          onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.targetData,
          rules: _ctx.rules,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "fw-bolder" }, "Add a New Event", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[8] || (_cache[8] = _createElementVNode("label", { class: "fs-6 fw-bold required mb-2" }, "Event Name", -1)),
                _createVNode(_component_el_form_item, { prop: "eventName" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.targetData.eventName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetData.eventName) = $event)),
                      type: "text",
                      name: "eventName"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "fv-plugins-message-container invalid-feedback" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[10] || (_cache[10] = _createElementVNode("label", { class: "fs-6 fw-bold mb-2" }, "Event Description", -1)),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.targetData.eventDescription,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.targetData.eventDescription) = $event)),
                  type: "text",
                  placeholder: "",
                  name: "eventDescription"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "fs-6 fw-bold mb-2" }, "Event Location", -1)),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.targetData.eventLocation,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.targetData.eventLocation) = $event)),
                  type: "text",
                  placeholder: "",
                  name: "eventLocation"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", _hoisted_12, [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.targetData.allDay,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.targetData.allDay) = $event)),
                    type: "checkbox"
                  }, null, 8, ["modelValue"]),
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "form-check-label fw-bold" }, "All Day", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _cache[13] || (_cache[13] = _createElementVNode("label", { class: "fs-6 fw-bold mb-2 required" }, "Event Start Date", -1)),
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.targetData.eventStartDate,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.targetData.eventStartDate) = $event)),
                      type: "text",
                      name: "eventStartDate"
                    }, null, 8, ["modelValue"]),
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "fv-plugins-message-container invalid-feedback" }, null, -1))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", { class: "fs-6 fw-bold mb-2 required" }, "Event End Date", -1)),
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.targetData.eventEndDate,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.targetData.eventEndDate) = $event)),
                      type: "text",
                      name: "eventName"
                    }, null, 8, ["modelValue"]),
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "fv-plugins-message-container invalid-feedback" }, null, -1))
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _cache[19] || (_cache[19] = _createElementVNode("button", {
                "data-bs-dismiss": "modal",
                type: "reset",
                id: "kt_modal_add_event_cancel",
                class: "btn btn-light me-3"
              }, " Cancel ", -1)),
              _createElementVNode("button", {
                "data-kt-indicator": _ctx.loading ? 'on' : null,
                class: "btn btn-lg btn-primary",
                type: "submit"
              }, [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                      _cache[17] || (_cache[17] = _createTextVNode(" Submit ")),
                      _createElementVNode("span", _hoisted_22, [
                        _createVNode(_component_inline_svg, { src: "icons/duotune/arrows/arr064.svg" })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_23, _cache[18] || (_cache[18] = [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_20)
            ]),
            _cache[20] || (_cache[20] = _createElementVNode("div", null, null, -1))
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ], 512))
}