import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_FullCalendar = _resolveComponent("FullCalendar")!
  const _component_NewEventModal = _resolveComponent("NewEventModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "card-title fw-bolder" }, "Calendar", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn btn-flex btn-primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newEvent()))
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
            ]),
            _cache[1] || (_cache[1] = _createTextVNode(" Add Event "))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FullCalendar, {
          class: "demo-app-calendar",
          options: _ctx.calendarOptions
        }, null, 8, ["options"])
      ])
    ]),
    _createVNode(_component_NewEventModal)
  ], 64))
}